.requiredAsterisk {
  white-space: nowrap;
  color: rgb(211, 47, 47);
}

.helperText {
  margin-top: 4px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  color: rgb(211, 47, 47);
}
i.helperText {
  color: var(--client-primary-color);
}

.previewContainer {
  width: 100%;
  padding: 4px;
  height: 200px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
  border: 1px dotted #cccccc;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.05);
}

.signaturePad {
  width: 100%;
  height: 200px;
}
.signatureName {
  width: 100%;
  padding: 6px;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
  color: var(--client-accent-color);
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.clearButton {
  top: 8px;
  right: 8px;
  z-index: 10;
  position: absolute !important;
  background-color: #eeeeee !important;
}
.clearButton:hover {
  color: #000000 !important;
  background-color: #dddddd !important;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1) !important;
}

.renderAsHtml a {
  font-weight: normal;
  text-decoration: underline;
}

.hiddenInput {
  position: absolute;
  margin: 0px !important;
  padding: 0px !important;
  width: 0px !important;
  height: 0px !important;
  border: none !important;
  box-shadow: none !important;
  color: transparent !important;
  background-color: transparent !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
.initialsField {
  height: 26px;
  display: flex;
  padding: 0 8px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 2px;
  margin-right: 12px;
  letter-spacing: 1px;
  align-items: center;
  transition: all 0.3s;
  justify-content: center;
  background-color: white;
  font-family: var(--font-yellowtail);
}

.buttonLabel span {
  text-transform: none;
}

.photoIcon {
  color: gray;
  font-size: 60px !important;
}

@media (max-width: 600px) {
  .formGroupOneColumn {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media (max-width: 600px) {
  .formGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {
  .formGroup {
    grid-template-columns: repeat(3, 1fr);
  }
}

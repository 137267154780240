.main {
  display: flex;
  padding: 32px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.main h2 {
  font-size: 16px;
  font-weight: 300;
}
.main h2 a {
  gap: 4px;
  display: flex;
  text-decoration: none;
  justify-content: right;
}
.main form p {
  font-weight: bold;
  font-style: italic;
  margin: 5px 0 10px 0;
}
.main form .contentContainer p {
  font-weight: initial;
  font-style: initial;
  margin: initial;
}

.patientName {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  color: var(--client-primary-color);
}
.mainTitle {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}
.mainDescription {
  max-width: 80%;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin: 0 auto 40px;
}

.formContainer {
  padding: 20px;
}

.header {
  margin-bottom: 20px;
}
.header img {
  width: 100%;
  height: auto;
}

.stepper {
  padding: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #000000;
  background: linear-gradient(to bottom, #222222, black);
}

.paperNotFound {
  padding: 80px 20px;
}
.maintenance {
  padding: 40px 20px;
}
.paperNotFound h1,
.maintenance h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 60px;
  text-align: center;
  margin-bottom: 20px;
  color: var(--client-primary-color);
}
.maintenance h1 {
  margin-top: -10px;
  line-height: 45px;
}
.paperNotFound h1 span {
  font-size: 65px;
  font-weight: 700;
  text-shadow: 5px 5px #eee;
}
.paperNotFound p,
.maintenance p {
  width: 100%;
  font-size: 16px;
  text-align: center;
}

.contentContainer {
  padding: 0 20px;
}
.contentContainer ul,
.contentContainer ol {
  margin: 10px 0 20px 20px;
}
.contentContainer ul li ul {
  list-style-type: square;
}
.contentContainer ol li ol {
  list-style-type: lower-alpha;
}
.contentContainer li {
  margin-bottom: 5px;
}
.contentContainer h1 {
  font-size: 18px;
  margin-bottom: 20px;
  color: var(--client-primary-color);
}

@media screen and (min-width: 1101px) {
  .header,
  .paper,
  .paperNotFound,
  .maintenance {
    width: 1100px;
  }
}
@media screen and (max-width: 1100px) {
  .header,
  .paper,
  .paperNotFound,
  .maintenance {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .main h2 {
    font-size: 14px;
  }
  .main h2 svg {
    font-size: 20px;
  }
  .mainTitle {
    font-size: 16px;
  }
  .mainDescription {
    font-size: 14px;
    max-width: 100%;
  }
}
